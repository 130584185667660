.ProseMirror {
    margin: 0;
    padding: 0.3rem 1rem;
  }

.ProseMirror h1 {
  
}

.ProseMirror h3 {
  
}

.ProseMirror p {
  
}
